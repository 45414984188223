
/* eslint-disable */
import CloudFun, {
  defineComponent,
  watch,
  ref,
  onMounted
} from "@cloudfun/core";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import "@/global-components/ckeditor/styles.css";

export default defineComponent({
  components: {},
  setup() {
    const model = CloudFun.current?.model;
    const router = CloudFun.current?.policy?.router;
    const templateData = ref({
      Id: 0,
      Name: "",
      Content: { Content: "" }
    });

    const editorOptions = {
      editor: ClassicEditor,
      config: {
        extraPlugins: [UploadAdapterPlugin],
        toolbar: {
          shouldNotGroupWhenFull: true,
          items: [
            "heading",
            "|",
            "fontFamily",
            "fontSize",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "bold",
            "italic",
            "underline",
            "alignment",
            "bulletedList",
            "numberedList",
            "outdent",
            "indent",
            "highlight",
            "insertTable",
            "|",
            "link",
            "blockQuote",
            "imageInsert",
            "mediaEmbed",
            // 'codeBlock',
            "htmlEmbed",
            "|",
            "|",
            "undo",
            "redo",
            "sourceEditing"
          ]
        },
        removePlugins: ["Markdown"],
        image: {
          toolbar: [
            "imageTextAlternative",
            "imageStyle:full",
            "imageStyle:side",
            "linkImage"
          ]
        },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableCellProperties",
            "tableProperties"
          ]
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph"
            },
            {
              model: "heading1",
              view: { name: "h1", classes: "font-bold text-xl" },
              title: "Heading 1",
              class: "ck-heading_heading1"
            },
            {
              model: "heading2",
              view: { name: "h2", classes: "font-bold text-base" },
              title: "Heading 2",
              class: "ck-heading_heading2"
            }
          ]
        }
      }
    }
    const save = () => {
      if (templateData.value.Id !== 0) {
        model?.dispatch("template/update", templateData.value).then(() => {
          CloudFun.send("info", {
            subject: "執行完成",
            content: "儲存成功"
          });
        });
      } else {
        model?.dispatch("template/insert", templateData.value).then(req => {
          console.log(req);
          templateData.value = req;
          CloudFun.send("info", {
            subject: "執行完成",
            content: "儲存成功"
          });
        });
      }
    };

    watch(() => router?.currentRoute.value.params["number"], async (value) => {
      console.log("NNNNNN", value)
      if (!value) return;
      try {
        const number = (value as string).replace("-", "_").toUpperCase();
        templateData.value = await model?.dispatch("template/find", number);
      } catch (error) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: error
        });
      }
    }, { immediate: true })

    return {
      templateData,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`,
      editorOptions,
      baseUrl: process.env.VUE_APP_BACKEND_URL,
      save
    };
  }
});
